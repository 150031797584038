<template>
  <div>
    <div class="locale">
      <h2><a-button @click="$router.back()" shape="circle" icon="arrow-left"/>修改人员</h2>
      <ol>
        <li><router-link to="/">后台</router-link></li>
        <li><a href="javascript:;">系统</a></li>
        <li><a href="javascript:;">人员</a></li>
        <li>修改</li>
      </ol>
    </div>

        <a-form-model ref="form" :rules="form.rules" :model="form.data" v-bind="form.layout" @submit="onSubmit" @submit.native.prevent>
          <a-card title="人员信息">

            <a-form-model-item prop="id" label="编号">
              <a-input type="text" :value="item.id" disabled />
            </a-form-model-item>

            <a-form-model-item prop="name" label="名字">
              <a-input ref="name" type="text" placeholder="人员名字" v-model="form.data.name" />
            </a-form-model-item>
            <a-form-model-item prop="role_ids" label="角色">
              <a-select
                :options="roles"
                mode="multiple"
                v-model="form.data.role_ids"
                placeholder="选择角色"
              />
            </a-form-model-item>

            <a-form-model-item prop="account" label="登录帐号">
              <a-input type="text" placeholder="推荐使用手机号做为登录帐号" v-model="form.data.account" />
            </a-form-model-item>

            <a-form-model-item prop="password" label="登录密码">
              <a-button @click="$router.replace('/sys/staff/password/' + item.id)">修改密码</a-button>
            </a-form-model-item>


            <a-divider orientation="left">个人信息</a-divider>

            <a-form-model-item prop="number" label="工号">
              <a-input type="text" placeholder="工号" v-model="form.data.number" />
            </a-form-model-item>

            <a-form-model-item prop="gender" label="性别">
              <a-radio-group :options="genders" v-model="form.data.gender" />
            </a-form-model-item>

            <a-form-model-item prop="title" label="头衔">
              <a-input ref="title" type="text" placeholder="头衔" v-model="form.data.title" />
            </a-form-model-item>
            <a-form-model-item prop="mobile" label="手机号">
              <a-input ref="mobile" type="text" placeholder="手机号" v-model="form.data.mobile" />
            </a-form-model-item>

            <a-form-model-item prop="email" label="电子邮箱">
              <a-input ref="email" type="text" placeholder="电子邮箱" v-model="form.data.email" />
            </a-form-model-item>

            <a-form-model-item prop="identity" label="身份证">
              <a-input ref="identity" type="text" placeholder="身份证号" v-model="form.data.identity" />
            </a-form-model-item>

            <a-form-model-item prop="birthday" label="出生日期">
              <a-date-picker v-model="form.data.birthday" />
            </a-form-model-item>

            <!-- <a-form-model-item prop="powerful" label="超级权限">
              <a-switch checked-children="是" un-checked-children="否" v-model="form.data.powerful"/>
            </a-form-model-item> -->

            <a-divider orientation="left">其它信息</a-divider>

            <a-form-model-item prop="remark" label="备注">
              <a-textarea ref="remark" rows="2"  placeholder="备注" v-model="form.data.remark" />
            </a-form-model-item>

          </a-card>

          <div class="submit">
            <a-button type="primary" html-type="submit">确认修改</a-button>
          </div>

        </a-form-model>
  </div>
</template>

<script>
  export default {
    name: 'SysStaffChange',
    data () {
      return {
        genders: [], roles:[],
        item: { organ: {} },
        form: {
          layout: {
            labelCol: { span: 6 },
            wrapperCol: { span: 10 },
          },
          rules: {
            name: [{ required: true, message: '名字不可为空' }],
            account: [{ required: true, message: '账号不可为空' }],
          },
          
          data: {
            role_ids: '', name: '', title: '', gender: '',
            number: '', mobile: '', email: '', account: '',
            identity: '', birthday: '', powerful: false, remark: ''
          }
        }
      }
    },
    mounted() {
      let id = this.$route.params.id;
      this.$get('/sys/staff/change/'+id).then( res => {
        let ajax = res.data;
        if (ajax.code == 0) {
          this.genders = ajax.data.genders;
          this.roles = ajax.data.roles;

          this.item = ajax.data.item;
          for (let k in this.form.data) {
              if (ajax.data.item[k] && ajax.data.item[k].value) {
                this.form.data[k] = ajax.data.item[k].value;
              } else if (k == 'birthday') {
                this.form.data[k] = new Date(ajax.data.item[k]).format('yyyy-MM-dd');
              } else {
                if (ajax.data.item[k] !== null) {
                  this.form.data[k] = ajax.data.item[k];
                }
              }
            }
        }
      })
    },

    methods: {

      onSubmit(){
        this.$refs['form'].validate(valid => {
          if (valid) {
            this.create();
          }
        });
      },
      create (){
      let id = this.$route.params.id;
        this.$post('/sys/staff/change/' + id, this.form.data).then(res => {
          let ajax = res.data;
          if (ajax.code == 0) {
            setTimeout(()=>{
              this.$router.go(-1);
            }, 500);

          }
        })

      }
    }
  }
</script>
<style lang="less" scoped>
  .profile {
  }
</style>
